<template>
  <div class="contract-content" v-loading="loading">
    <el-table :data="sgTradexGroupedTransactions" row-key="id" border :header-cell-style="HEADER_CELL_STYLE" :cell-style="CELL_STYLE" width="50%">
      <el-table-column label="" width="35px" show-overflow-tooltip></el-table-column>
      <el-table-column label="Status" prop="status" width="120px" show-overflow-tooltip></el-table-column>
      <el-table-column label="Participant" prop="participantName" min-width="120px" show-overflow-tooltip></el-table-column>
      <el-table-column label="Error Message" prop="errorMessage" min-width="240px" show-overflow-tooltip></el-table-column>
      <el-table-column label="Date and Time" prop="dateTime" width="180px" show-overflow-tooltip></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
export default {
  name: 'SgTradexTransactionInformation',
  props: {
    sgTradexTransactionData: Array,
    loading: Boolean
  },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE
    }
  },
  computed: {
    sgTradexGroupedTransactions () {
      const groupedItems = this.sgTradexTransactionData.reduce((accumulator, item) => {
        const participantId = item.participantId;
        if (!accumulator[participantId]) {
          accumulator[participantId] = []
        }
        accumulator[participantId].push(item);
        return accumulator
      }, {})

      const result = []
      for (const [key, value] of Object.entries(groupedItems)) {
        const item = {
          id: key,
          status: '',
          participantId: key,
          participantName: value[0].participantName,
          errorMessage: '',
          dateTime: '',
          children: value.map(transaction => {
            return {
              id: transaction.id,
              status: transaction.isSuccessful === null ? 'Pending' : transaction.isSuccessful ? 'Successful' : 'Unsuccessful',
              participantId: transaction.participantId,
              participantName: transaction.participantName,
              errorMessage: transaction.isSuccessful === false ? (transaction.pushRequest.sgTradexResponseMessageJson ? transaction.pushRequest.sgTradexResponseMessageJson : transaction.pushRequest.errorMessage) : '-',
              dateTime: transaction.createdAt ? this.$moment(transaction.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-'
            }
          })
        }
        result.push(item)
      }
      return result
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
