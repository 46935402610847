var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "contract-content",
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.sgTradexGroupedTransactions,
            "row-key": "id",
            border: "",
            "header-cell-style": _vm.HEADER_CELL_STYLE,
            "cell-style": _vm.CELL_STYLE,
            width: "50%",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "", width: "35px", "show-overflow-tooltip": "" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Status",
              prop: "status",
              width: "120px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Participant",
              prop: "participantName",
              "min-width": "120px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Error Message",
              prop: "errorMessage",
              "min-width": "240px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Date and Time",
              prop: "dateTime",
              width: "180px",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }