var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowBiofuelThreeFields
    ? _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { sm: 24, md: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "blendRatio",
                    label: "Blend Ratio (For MPA Reporting)",
                    rules: _vm.getRule,
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.schedulFormData.blendRatio,
                      callback: function ($$v) {
                        _vm.$set(_vm.schedulFormData, "blendRatio", $$v)
                      },
                      expression: "schedulFormData.blendRatio",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { sm: 24, md: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "biofuelGenCode",
                    label: "Biofuel Gen Code (For MPA Reporting)",
                    rules: _vm.getRule,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Select" },
                      on: { "visible-change": _vm.getMpaBioFuelGenCodeOptions },
                      model: {
                        value: _vm.schedulFormData.biofuelGenCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.schedulFormData, "biofuelGenCode", $$v)
                        },
                        expression: "schedulFormData.biofuelGenCode",
                      },
                    },
                    _vm._l(_vm.biofuelGenCodeOptions, function (item) {
                      return _c("el-option", {
                        key: item.biofuelGenCode,
                        attrs: {
                          label: item.biofuelGenDescription,
                          value: item.biofuelGenCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { sm: 24, md: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "biofuelISCC",
                    label: "Biofuel ISCC (For MPA Reporting)",
                    rules: _vm.getRule,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Select" },
                      model: {
                        value: _vm.schedulFormData.biofuelISCC,
                        callback: function ($$v) {
                          _vm.$set(_vm.schedulFormData, "biofuelISCC", $$v)
                        },
                        expression: "schedulFormData.biofuelISCC",
                      },
                    },
                    _vm._l(_vm.YES_NO_OPTION_CHAR, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isShowBiofuelISCCValue
            ? _c(
                "el-col",
                { attrs: { sm: 24, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "biofuelISCCValue",
                        label: "Biofuel ISCC Value (For MPA Reporting)",
                        rules: _vm.isShowBiofuelISCCValue ? _vm.REQ_RULE : null,
                      },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { precision: 2, min: 0.0, max: 99.99 },
                        model: {
                          value: _vm.schedulFormData.biofuelISCCValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schedulFormData,
                              "biofuelISCCValue",
                              $$v
                            )
                          },
                          expression: "schedulFormData.biofuelISCCValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowBiofuelISCCScheme
            ? _c(
                "el-col",
                { attrs: { sm: 24, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "biofuelCertScheme",
                        label: "Biofuel Cert Scheme (For MPA Reporting)",
                        rules: _vm.isShowBiofuelISCCScheme
                          ? _vm.REQ_RULE
                          : null,
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.schedulFormData.biofuelCertScheme,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schedulFormData,
                              "biofuelCertScheme",
                              $$v
                            )
                          },
                          expression: "schedulFormData.biofuelCertScheme",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowTypeOfFeedstock
            ? _c(
                "el-col",
                { attrs: { sm: 24, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "typeOfFeedstock",
                        label: "Type Of Feedstock (For MPA Reporting)",
                        rules: _vm.isShowTypeOfFeedstock ? _vm.REQ_RULE : null,
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.schedulFormData.typeOfFeedstock,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schedulFormData,
                              "typeOfFeedstock",
                              $$v
                            )
                          },
                          expression: "schedulFormData.typeOfFeedstock",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }