var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contract-content" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.mpaTransactionData,
            border: "",
            "header-cell-style": _vm.HEADER_CELL_STYLE,
            "cell-style": _vm.CELL_STYLE,
            width: "50%",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "Status",
              width: "160px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.isSuccessful ? "Successful" : "Unsuccessful"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Error Message",
              "min-width": "240px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.errorMessage ? scope.row.errorMessage : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Date and Time",
              width: "180px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.updatedAt
                            ? _vm
                                .$moment(scope.row.updatedAt)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Via SgTradex",
              width: "180px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.sgTradexPushRequest ? "YES" : "NO") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }